import { useMemo } from 'react';
import styled from 'styled-components';

import { SOURCE_KAJABI, SOURCE_VIDAPP } from 'api';
import { CustomCell, CustomTable, SearchBar, StyledToggle, TableColumn, ToggleWrapper, TypeLabel } from 'components';
import { FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useAppProperties } from 'hooks';
import { BuilderProduct, useDataSource, useLocalProducts, useSaveContext } from 'providers';
import { getDataSourceDisplayName, isUserAdmin } from 'utils';

import { DeleteOfferButton } from './DeleteOfferButton';
import { OffersHeading } from './OffersHeading';
import { useOffers } from '../../hooks/useOffers';

const PRODUCT_TYPE_MAP: Record<string, string> = {
  purchase: 'BUY TO OWN',
  subscription: 'SUBSCRIPTION',
  'email-capture': 'EMAIL CAPTURE',
};

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
  ${FONT_14PX_MEDIUM};
`;

const Spacer = styled.div`
  height: 20px;
`;

const DeleteWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OffersTable = () => {
  const { searchValue, handleSearch } = useOffers();
  const { products, setProductValue, setActiveProductId } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();
  const appDataSource = useDataSource();

  const isReadOnly = appProperties?.RolloutOffersScreen !== '1' && !isUserAdmin();

  const dataSource = useMemo(() => {
    if (!products) {
      return [];
    }

    const productArr = [...products.values()];

    if (searchValue === '') {
      return productArr;
    }

    const filterFunction = (value: string) => value.toLowerCase().includes(searchValue);

    return productArr.filter(
      ({ DisplayName, CustomDisplayName, SourceProductId, ProductId }) =>
        filterFunction(DisplayName) ||
        filterFunction(CustomDisplayName) ||
        filterFunction(SourceProductId) ||
        filterFunction(ProductId),
    );
  }, [products, searchValue]);

  const columns: TableColumn<BuilderProduct & { id: string }>[] = useMemo(() => {
    const arr: TableColumn<BuilderProduct & { id: string }>[] = [
      {
        heading: 'Internal Title',
        width: 'grow',
        render: ({ DisplayName }) => (
          <CustomCell>
            <Ellipsis>{DisplayName}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'External Title',
        width: 'grow',
        render: ({ CustomDisplayName }) => (
          <CustomCell>
            <Ellipsis>{CustomDisplayName}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Data Source',
        width: 125,
        render: ({ DataSource }) => (
          <CustomCell>
            <Ellipsis>{getDataSourceDisplayName(DataSource)}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Source Offer ID',
        width: 113,
        render: ({ SourceProductId }) => (
          <CustomCell>
            <Ellipsis>{SourceProductId}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Type',
        width: 108,
        render: ({ ProductType }) => {
          const type = PRODUCT_TYPE_MAP[ProductType] ?? ProductType.toUpperCase();
          return (
            <CustomCell>
              <TypeLabel $disabled>{type}</TypeLabel>
            </CustomCell>
          );
        },
      },
      {
        heading: 'Logs In',
        width: 72,
        render: ({ AvailableForLogin, Id, ReadyForSale }) => (
          <ToggleWrapper onClick={(e) => e.stopPropagation()}>
            <CustomCell>
              <StyledToggle
                checked={AvailableForLogin === 'yes'}
                disabled={ReadyForSale === 'yes' || isReadOnly}
                checkedChildren="ON"
                onChange={(checked) => {
                  const newValue = checked ? 'yes' : 'no';
                  setProductValue(Id, 'AvailableForLogin', newValue);
                  setProductValueToSave(Id, 'AvailableForLogin', newValue);
                }}
              />
            </CustomCell>
          </ToggleWrapper>
        ),
      },
      {
        heading: 'For Sale',
        width: 72,
        render: ({ ReadyForSale, Id }) => (
          <ToggleWrapper onClick={(e) => e.stopPropagation()}>
            <CustomCell>
              <StyledToggle
                checked={ReadyForSale === 'yes'}
                disabled={isReadOnly}
                checkedChildren="ON"
                onChange={(checked) => {
                  const newValue = checked ? 'yes' : 'no';
                  setProductValue(Id, 'ReadyForSale', newValue);
                  setProductValueToSave(Id, 'ReadyForSale', newValue);

                  // If ReadyForSale is 'yes', AvailableForLogin is forced to be 'yes' and toggle is disabled
                  if (checked) {
                    setProductValue(Id, 'AvailableForLogin', 'yes');
                    setProductValueToSave(Id, 'AvailableForLogin', 'yes');
                  }
                }}
              />
            </CustomCell>
          </ToggleWrapper>
        ),
      },
    ];

    if (appDataSource === SOURCE_VIDAPP) {
      arr.push({
        heading: 'Web Sale',
        width: 80,
        render: ({ ReadyForSaleWeb, Id }) => (
          <ToggleWrapper onClick={(e) => e.stopPropagation()}>
            <CustomCell>
              <StyledToggle
                checked={ReadyForSaleWeb === 'yes'}
                disabled={isReadOnly}
                checkedChildren="ON"
                onChange={(checked) => {
                  const newValue = checked ? 'yes' : 'no';
                  setProductValue(Id, 'ReadyForSaleWeb', newValue);
                  setProductValueToSave(Id, 'ReadyForSaleWeb', newValue);
                }}
              />
            </CustomCell>
          </ToggleWrapper>
        ),
      });
    }

    if (!isReadOnly) {
      arr.push({
        heading: '',
        id: 'delete',
        width: 30,
        render: ({ Id, DataSource }) => (
          <DeleteWrapper onClick={(e) => e.stopPropagation()}>
            <CustomCell>{DataSource !== SOURCE_KAJABI && <DeleteOfferButton productId={Id} />}</CustomCell>
          </DeleteWrapper>
        ),
      });
    }

    return arr;
  }, [setProductValue, setProductValueToSave]);

  const onRowClick = (data: BuilderProduct) => {
    setActiveProductId(data.Id);
  };

  return (
    <>
      <OffersHeading />
      <SearchBar value={searchValue} onSearch={handleSearch} />
      <Spacer />
      <CustomTable
        pageSize={25}
        columns={columns}
        data={dataSource.map((offer) => ({ ...offer, id: offer.Id.toString() }))}
        onRowClick={isReadOnly ? undefined : onRowClick}
        query={searchValue}
        emptyTitle="No Offers"
        emptyDescription=""
      />
    </>
  );
};
