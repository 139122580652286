import { axiosReact } from './axios';

interface AppUser {
  Id: number;
  UserId: string;
  Email: string;
  LastLoginTs: number;
  DataSource: string;
}

export const searchAppUsers = (app_id: string, user_ids: string[]) => {
  return axiosReact
    .post<{ users: AppUser[] }>('/app_users?page_size=100000', {
      app_id,
      user_ids,
    })
    .then(({ data }) => {
      const USER_BY_USER_ID: Record<string, AppUser> = {};
      for (const user of data.users) {
        USER_BY_USER_ID[user.UserId] = user;
      }
      return { users: data.users, user_by_user_id: USER_BY_USER_ID };
    });
};
