import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { useCollections } from 'hooks';
import { FONT_12PX_MEDIUM } from 'font';
import { getCommunityIdFromCollections, padStart } from 'utils';
import { CustomTable, CustomCell, PageContainer, TableColumn } from 'components';
import { AmityPost } from 'api';

import { useCommunityPosts } from 'app/modules/community/Posts/hooks/useCommunityPosts';
import { CommunityPostModal } from 'app/modules/community/Posts/components/CommunityPostModal';
import {
  CommunityPostsProvider,
  useCommunityPostContext,
} from 'app/modules/community/Posts/providers/CommunityPostProvider';
import { Center, EllipsisText, SearchBox, StyledSearchIcon } from 'app/modules/community/Shared';
import { ImageIcon } from 'icons';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

const NumberCell = styled(CustomCell)`
  ${FONT_12PX_MEDIUM};
`;

const MemberCell = ({ data }: { data: AmityPost }) => {
  const { users } = useCommunityPostContext();
  return (
    <CustomCell>
      <EllipsisText>{users[data.postedUserId].displayName}</EllipsisText>
    </CustomCell>
  );
};

const columns: TableColumn<AmityPost & { id: string }>[] = [
  {
    heading: 'Member',
    width: 124,
    render: (data) => <MemberCell data={data} />,
  },
  {
    heading: 'Content',
    width: 'grow',
    render: (data) => (
      <CustomCell>
        <EllipsisText>{data.data.text}</EllipsisText>
      </CustomCell>
    ),
  },
  {
    heading: 'Image',
    width: 60,
    render: (data) => (
      <CustomCell>
        {data.children.length > 0 && (
          <Center>
            <ImageIcon />
          </Center>
        )}
      </CustomCell>
    ),
  },
  {
    heading: 'Comments',
    width: 94,
    render: (data) => <NumberCell>{padStart(data.commentsCount, 3)}</NumberCell>,
  },
  {
    heading: 'Reactions',
    width: 72,
    render: (data) => <NumberCell>{padStart(data.reactionsCount, 3)}</NumberCell>,
  },
  {
    heading: 'Flags',
    width: 56,
    render: (data) => <NumberCell>{padStart(data.flagCount, 3)}</NumberCell>,
  },
];

export const CommunityPostsTable = () => {
  const { data } = useCollections();
  const [modalPostId, setModalPostId] = useState<string>('');

  const communityId = useMemo(() => {
    if (!data) {
      return '';
    }
    return getCommunityIdFromCollections(data.collections);
  }, [data]);

  const { posts } = useCommunityPosts(communityId ?? '', {});
  const [query, setQuery] = useState<string>('');

  const filteredPosts = useMemo(() => {
    return Object.values(posts)
      .filter((p) => !p.isDeleted)
      .filter((p) => (query ? p.data.text.includes(query) : true))
      .map((post) => ({
        ...post,
        id: post._id,
      }))
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [posts, query]);

  const onRowClick = (data: AmityPost) => {
    setModalPostId(data.postId);
  };

  if (!communityId) {
    // There is no CommunityID on any of the tabs
    // Need some sort of default state
    return (
      <PageContainer
        heading="Posts"
        subheading="Community is not setup, Contact VidApp for support with this feature"
      ></PageContainer>
    );
  }

  return (
    <PageContainer heading="Posts" contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}>
      <CommunityPostsProvider communityId={communityId} modalPostId={modalPostId} setModalPostId={setModalPostId}>
        <SearchBox
          width="50%"
          prefix={<StyledSearchIcon />}
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
          size="middle"
        />
        <CustomTable
          columns={columns}
          data={filteredPosts}
          query={query}
          emptyTitle="No posts"
          emptyDescription="No one has posted yet."
          onRowClick={onRowClick}
        />
        {modalPostId && <CommunityPostModal data={posts[modalPostId]} />}
      </CommunityPostsProvider>
    </PageContainer>
  );
};
