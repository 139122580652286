import { useCallback, useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import styled from 'styled-components';

import { AppPropertyToSave } from 'api';
import { useAppBeingEdited } from 'app-context';
import { Collapse, CustomButton, CustomLink, TypeLabel } from 'components';
import { FONT_12PX_REGULAR, FONT_14PX_MEDIUM, FONT_14PX_SEMIBOLD } from 'font';
import { usePricingPlan, useSaveAppProperties } from 'hooks';
import { CheckCircleIcon, ExternalLinkIcon, PadlockIcon } from 'icons';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_6_COLOUR,
  NEUTRAL_8_COLOUR,
  SUCCESS_COLOUR,
} from 'theme';
import { isUserAdmin } from 'utils';

import {
  OnboardingStepKey,
  STEP_KEY_ANDROID_CREDENTIALS,
  STEP_KEY_ANDROID_CURRENCY,
  STEP_KEY_APPLE_CREDENTIALS,
  STEP_KEY_APPLE_DEVELOPER,
  STEP_KEY_APP_STORE_FRONT,
  STEP_KEY_BOOK_CALL,
  STEP_KEY_BUILD_APP_CONTENT,
  STEP_KEY_EXPLORE,
  STEP_KEY_KAJABI_OFFERS_FOR_SALE,
  STEP_KEY_OFFERS_CREATED,
  STEP_KEY_ONBOARDING_SCREENS,
  STEP_KEY_PAYMENT,
  STEP_KEY_RELEASE_ANDROID_APP,
  STEP_KEY_RELEASE_IOS_APP,
  STEP_KEY_VHX_ADMIN,
  STEP_KEY_VHX_OFFERS_FOR_SALE,
} from '../const';
import { STATUS_READY_TO_RELEASE, useOnboardingChecklistContext } from '../providers/OnboardingChecklistProvider';

const StyledLink = styled(CustomLink)`
  &&&& {
    text-decoration: none;
  }
`;

// Descriptions that contain formatting/links
const DESCRIPTIONS: Record<string, JSX.Element> = {
  [STEP_KEY_APPLE_CREDENTIALS]: (
    <>
      Go to our{' '}
      <StyledLink
        to={{
          pathname: `/app-store-settings`,
          state: { tab: 'ios' },
        }}
      >
        App Store Settings
      </StyledLink>{' '}
      page and fill in all the required fields.{' '}
      <a target="_blank" rel="noreferrer" href="https://wearevidapp.zendesk.com/hc/en-nz/requests/new">
        Contact us
      </a>{' '}
      if you need assistance.
    </>
  ),
  [STEP_KEY_ANDROID_CREDENTIALS]: (
    <>
      Go to our{' '}
      <StyledLink
        to={{
          pathname: `/app-store-settings`,
          state: { tab: 'android' },
        }}
      >
        App Store Settings
      </StyledLink>{' '}
      page and fill in all the required fields.{' '}
      <a target="_blank" rel="noreferrer" href="https://wearevidapp.zendesk.com/hc/en-nz/requests/new">
        Contact us
      </a>{' '}
      if you need assistance.
    </>
  ),
  [STEP_KEY_ANDROID_CURRENCY]: (
    <>
      Go to our{' '}
      <StyledLink
        to={{
          pathname: `/app-store-settings`,
          state: { tab: 'android' },
        }}
      >
        App Store Settings
      </StyledLink>{' '}
      page and ensure the currency matches your Google Play Store's selected currency for successful integration.
    </>
  ),
  [STEP_KEY_OFFERS_CREATED]: (
    <>
      After setting up Kajabi Offers for in-app subscriptions, sync them to VidApp in our{' '}
      <StyledLink to={{ pathname: `/offers` }}>Offers page</StyledLink>.
    </>
  ),
  [STEP_KEY_KAJABI_OFFERS_FOR_SALE]: (
    <>
      After syncing your Kajabi Offers, mark the{' '}
      <StyledLink to={{ pathname: `/offers` }}>subscription offers</StyledLink> you wish to display in your mobile app
      as "For Sale".
    </>
  ),
  [STEP_KEY_VHX_OFFERS_FOR_SALE]: (
    <>
      Once your Vimeo OTT Offers have synced, mark the{' '}
      <StyledLink to={{ pathname: `/offers` }}>subscription offers</StyledLink> you wish to display in your mobile app
      as "For Sale".
    </>
  ),
  [STEP_KEY_APP_STORE_FRONT]: (
    <>
      Visit the App Store page and complete all details to accurately represent your app in the{' '}
      <StyledLink to={{ pathname: `/app-store/ios` }}>iOS</StyledLink> and{' '}
      <StyledLink to={{ pathname: `/app-store/android` }}>Android</StyledLink> stores
    </>
  ),
  [STEP_KEY_BUILD_APP_CONTENT]: (
    <>
      Finalize your app’s layout and structure using our intuitive{' '}
      <StyledLink to={{ pathname: `/builder` }}>app builder</StyledLink>. Changes can still be made after your app goes
      live.
    </>
  ),
};

// Help text that contain formatting/links
const HELP_TEXT: Record<string, JSX.Element> = {
  [STEP_KEY_APPLE_DEVELOPER]: (
    <>
      It's crucial to enroll as an <b>Organization</b> to launch your apps. Please ensure you do not enroll as an
      Individual/Sole-Proprietor.
    </>
  ),
  [STEP_KEY_VHX_ADMIN]: (
    <>
      <ol>
        <li>
          Access the Admin dashboard and navigate to <b>Manage {'>'} Team</b>
        </li>
        <li>
          Click <b>Invite</b> and enter the details below to add VidApp as a user on your Vimeo OTT account: <br />
          <br />
          <b>Member Name:</b> [yourcompanyname]@vidapp.com
          <br />
          <b>Role:</b> Admin
        </li>
      </ol>
    </>
  ),
  [STEP_KEY_OFFERS_CREATED]: (
    <>
      To enable in-app purchases, please first create Kajabi Offers. This allows us to link your in-app purchases
      directly to Kajabi, ensuring that new subscriptions initiated in the app automatically create Kajabi accounts.
      Subscribers can then freely access content across both the app and your Kajabi site if they have the appropriate
      Offer.
      <br />
      <br />
      When configuring Kajabi Offers, create separate offers for each subscription duration—e.g., one for monthly and
      one for annual subscriptions. Include the pricing and any free trial options.
    </>
  ),
  [STEP_KEY_RELEASE_IOS_APP]: (
    <>
      <ol>
        <li>
          Log into{' '}
          <a target="_blank" rel="noreferrer" href="https://appstoreconnect.apple.com/">
            App Store Connect
          </a>
        </li>
        <li>
          Click <b>Apps</b> and select your app
        </li>
        <li>
          Click <b>iOS</b> or <b>tvOS</b> (whichever app you wish to release)
        </li>
        <li>
          If your app version is ready to be released manually, click the blue <b>Release This Version</b> button
        </li>
      </ol>
    </>
  ),
  [STEP_KEY_RELEASE_ANDROID_APP]: (
    <>
      <ol>
        <li>
          Log into{' '}
          <a target="_blank" rel="noreferrer" href="https://play.google.com/console/u/0/developers/">
            Google Play Console
          </a>
        </li>
        <li>Select your app</li>
        <li>
          Click <b>Publishing overview</b>
        </li>
        <li>
          Click <b>Publish changes</b>
        </li>
      </ol>
    </>
  ),
};

const Container = styled.div`
  padding-bottom: 28px;
`;

export const StepDivider = styled.div`
  height: 29px;
  border-top: 1px solid ${NEUTRAL_5_COLOUR};
`;

const CollapseHeader = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  flex-grow: 1;

  height: 44px;
  ${FONT_14PX_SEMIBOLD};
  color: ${({ $isDisabled }) => ($isDisabled ? NEUTRAL_8_COLOUR : NEUTRAL_10_COLOUR)};
`;

const StyledPadlock = styled(PadlockIcon)<{ $isDisabled?: boolean }>`
  &&&& {
    font-size: 20px;
    margin-right: 4px;
    svg {
      color: ${({ $isDisabled }) => ($isDisabled ? NEUTRAL_8_COLOUR : NEUTRAL_10_COLOUR)};
    }
  }
`;
const StyledCheck = styled(CheckCircleIcon)<{ $completed: boolean }>`
  &&& {
    font-size: 20px;
    color: ${({ $completed }) => ($completed ? SUCCESS_COLOUR : NEUTRAL_6_COLOUR)};
    margin-right: 4px;
  }
`;

const Content = styled.div`
  margin-top: 8px;
`;

const Description = styled.div`
  margin-bottom: 24px;
`;

const HelpText = styled.div`
  margin-bottom: 24px;
  padding: 12px 16px;
  ${FONT_12PX_REGULAR};
  background-color: ${NEUTRAL_3_COLOUR};
  border-radius: 8px;

  ol {
    padding: inherit;
  }

  ol > li {
    margin-bottom: 5px;
    ::marker {
      font-weight: bold;
    }
  }
`;

const BlockerText = styled(HelpText)`
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  background-color: ${HIGHLIGHT_SECONDARY_COLOUR};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CTAButtons = styled.div`
  display: flex;
`;

const Link = styled.a`
  display: flex;
  align-items: center;

  height: 30px;
  ${FONT_14PX_MEDIUM};
`;

const StyledLinkIcon = styled(ExternalLinkIcon)`
  margin-left: 2px;
`;

const StyledTypeLabel = styled(TypeLabel)`
  margin-left: 4px;
`;

const UsePlaceholderButton = styled(CustomButton)`
  &&& {
    color: ${NEUTRAL_8_COLOUR};
    margin-right: 8px;
  }
`;

interface OnboardingStepProps {
  id: OnboardingStepKey;
  isAdmin?: boolean;
  isDisabled?: boolean;
}

export const OnboardingStep = ({ id, isAdmin, isDisabled }: OnboardingStepProps) => {
  const {
    onboardingSteps,
    toggleOnboardingStepStatus,
    completeOnboardingStep,
    onboardingScreensConfig,
    setSalesCallModalVisible,
    submissionStatuses,
  } = useOnboardingChecklistContext();
  const saveAppProperties = useSaveAppProperties();
  const { data: pricingPlan } = usePricingPlan();
  const appId = useAppBeingEdited();
  const tour = useContext(ShepherdTourContext);

  const onExploreClick = useCallback(() => {
    tour?.start();
  }, [tour]);

  const onPaymentClick = useCallback(() => {
    if (pricingPlan) {
      window.open(`php/payment.php?app_id=${appId}`, '_blank', 'noopener,noreferrer');
    }
  }, [pricingPlan]);

  const onBookCallClick = useCallback(() => {
    setSalesCallModalVisible(true);
  }, [setSalesCallModalVisible]);

  const onOnboardingScreensClick = useCallback(() => {
    if (!onboardingScreensConfig.onboardingScreenIsSet || !onboardingScreensConfig.onboardingBackgroundIsSet) {
      const propertiesToSave: AppPropertyToSave[] = [];

      if (!onboardingScreensConfig.onboardingScreenIsSet) {
        propertiesToSave.push({
          Name: 'OnboardingScreens',
          Value: JSON.stringify([
            {
              ImageName: onboardingScreensConfig.splashScreenUrl,
              Position: 1,
              Type: 'image',
            },
          ]),
        });
      }
      if (!onboardingScreensConfig.onboardingBackgroundIsSet) {
        propertiesToSave.push({
          Name: 'OnboardingScreenBackground',
          Value: onboardingScreensConfig.splashScreenUrl ?? '',
        });
      }

      saveAppProperties.mutate(propertiesToSave);
      completeOnboardingStep(STEP_KEY_ONBOARDING_SCREENS);
    } else {
      toggleOnboardingStepStatus(STEP_KEY_ONBOARDING_SCREENS);
    }
  }, [onboardingScreensConfig, saveAppProperties, completeOnboardingStep, toggleOnboardingStepStatus]);

  const onReleaseAppClick = useCallback(
    (stepKey: OnboardingStepKey) => {
      if (onboardingSteps) {
        const isCompleting = !onboardingSteps[stepKey].isCompleted;

        toggleOnboardingStepStatus(stepKey);

        if (isCompleting) {
          const otherPlatformKey =
            stepKey === STEP_KEY_RELEASE_IOS_APP ? STEP_KEY_RELEASE_ANDROID_APP : STEP_KEY_RELEASE_IOS_APP;
          const otherPlatformIsReleased = !!onboardingSteps[otherPlatformKey].isCompleted;

          // Both platforms have been marked as released, so onboarding checklist should now be hidden
          if (otherPlatformIsReleased) {
            saveAppProperties.mutate([
              {
                Name: 'DisplayFullOnboardingChecklist',
                Value: '0',
              },
            ]);
          }
        }
      }
    },
    [onboardingSteps, saveAppProperties, toggleOnboardingStepStatus],
  );

  const onClickMap: Record<string, () => void> = {
    [STEP_KEY_EXPLORE]: onExploreClick,
    [STEP_KEY_PAYMENT]: onPaymentClick,
    [STEP_KEY_BOOK_CALL]: onBookCallClick,
    [STEP_KEY_ONBOARDING_SCREENS]: onOnboardingScreensClick,
    [STEP_KEY_RELEASE_IOS_APP]: () => onReleaseAppClick(STEP_KEY_RELEASE_IOS_APP),
    [STEP_KEY_RELEASE_ANDROID_APP]: () => onReleaseAppClick(STEP_KEY_RELEASE_ANDROID_APP),
  };

  const buttonAction = onClickMap[id];

  if (!onboardingSteps || (isAdmin && !isUserAdmin())) {
    return null;
  }

  const step = onboardingSteps[id];
  const { title, dependency, blockerText, buttonText, link, isCompleted, hideButtonIfComplete } = step;

  const description = DESCRIPTIONS[id] ?? step.description;
  const helpText = HELP_TEXT[id] ?? step.helpText;

  const isLockedViaDependency = !!dependency && !onboardingSteps[dependency].isCompleted;
  const isPaymentLocked = id === STEP_KEY_PAYMENT && !pricingPlan;
  const isReleaseLocked =
    (id === STEP_KEY_RELEASE_IOS_APP && submissionStatuses.iOS !== STATUS_READY_TO_RELEASE) ||
    (id === STEP_KEY_RELEASE_ANDROID_APP && submissionStatuses.android !== STATUS_READY_TO_RELEASE);

  const isLocked = !isCompleted && (isLockedViaDependency || isPaymentLocked || isReleaseLocked);

  const isButtonHidden = !!hideButtonIfComplete && !!isCompleted;

  return (
    <Container id={id === STEP_KEY_BOOK_CALL ? 'Checklist__Item--call' : undefined}>
      <StepDivider />
      <Collapse
        triggerContent={
          <CollapseHeader $isDisabled={isDisabled}>
            {isDisabled || isLocked ? (
              <StyledPadlock $isDisabled={isDisabled} />
            ) : (
              <StyledCheck $completed={!!isCompleted} />
            )}
            {title}
            {isAdmin && <StyledTypeLabel $warning>ADMIN</StyledTypeLabel>}
          </CollapseHeader>
        }
        fullWidthTrigger
        iconAtEnd
        chevronIcon
        disableTrigger={isDisabled}
      >
        <Content>
          {isLocked && blockerText && <BlockerText>{blockerText}</BlockerText>}
          {description && <Description>{description}</Description>}
          {helpText && <HelpText>{helpText}</HelpText>}
          <Footer>
            {link ? (
              <Link target="_blank" rel="noreferrer" href={link.url}>
                {link.title}
                <StyledLinkIcon />
              </Link>
            ) : (
              <div></div>
            )}
            <CTAButtons>
              {id === STEP_KEY_ONBOARDING_SCREENS &&
                (!onboardingScreensConfig.onboardingScreenIsSet ||
                  !onboardingScreensConfig.onboardingBackgroundIsSet) && (
                  <UsePlaceholderButton medium tertiary onClick={buttonAction}>
                    Use Placeholder
                  </UsePlaceholderButton>
                )}
              {!isButtonHidden && (
                <CustomButton
                  medium
                  icon={!buttonText && <CheckCircleIcon />}
                  onClick={() => {
                    if (buttonAction) {
                      buttonAction();
                    } else {
                      toggleOnboardingStepStatus(id);
                    }
                  }}
                  disabled={isLocked}
                  success={!!isCompleted}
                >
                  {buttonText ? buttonText : isCompleted ? 'Completed' : 'Mark Completed'}
                </CustomButton>
              )}
            </CTAButtons>
          </Footer>
        </Content>
      </Collapse>
    </Container>
  );
};
