import { ITEM_TYPE_SECTIONHEADER, Resource, VIDEO_TYPE_SECTIONHEADER, Video } from 'api';
import { BuilderCollection, BuilderVideo } from 'providers';
import { secondsToTime } from 'utils/DateUtils';

export type VideoNameProps = Pick<Video, 'Title' | 'SourceTitle' | 'Description' | 'Type'>;

export const getVideoName = (video: VideoNameProps) => {
  if (video.Type.toLowerCase() === VIDEO_TYPE_SECTIONHEADER.toLowerCase()) {
    return video.Description;
  }
  return video.Title || video.SourceTitle;
};

export const getVideoSourceTitle = (video: Video) =>
  video.SourceTitle && video.SourceTitle !== '' ? video.SourceTitle : video.Title;

export const AUDIO_EXTENSIONS = ['wav', 'mp3', 'aac', 'm4a'];

// Each of these options returns false if not specified
interface CheckVideoOptions {
  audio?: boolean; // return true for audio
  pdf?: boolean; // return true for pdfs
  text?: boolean; // return true for text-only posts
}

export const checkVideoType = (video: Video | BuilderVideo, options?: CheckVideoOptions) => {
  const { audio, pdf, text } = options ?? {};
  if (!audio) {
    for (const type of AUDIO_EXTENSIONS) {
      if (video.OriginalFilename?.endsWith(`.${type}`)) {
        return false;
      }
    }
  }
  if (!pdf) {
    if (video.OriginalFilename?.endsWith('.pdf')) {
      return false;
    }
  }
  if (text) {
    if (video.Type === 'post') {
      // Allow type post
      return true;
    }
  }
  // Otherwise type should be video
  return video?.Type === 'video';
};

export const getVideoDescription = (video: Pick<Video, 'Description' | 'SourceDescription'>) => {
  return video.Description || video.SourceDescription;
};
export const getVideoHTMLDescription = (video: Pick<Video, 'Description' | 'SourceDescription'>) => {
  return ((video.Description || video.SourceDescription) ?? '').replaceAll('\n', '<br/>');
};

export const isResource = (video: BuilderVideo) => {
  const resourceExtensions = [...AUDIO_EXTENSIONS, 'pdf', 'png', 'jpg', 'jpeg'];
  const fileParts = video.OriginalFilename?.split('.') ?? [];
  if (fileParts.length < 2) {
    return false;
  }
  const extension = fileParts.pop();
  return extension ? resourceExtensions.includes(extension) : false;
};

export const isSectionHeader = (item: BuilderVideo | BuilderCollection) =>
  item.Type.toLowerCase() === ITEM_TYPE_SECTIONHEADER.toLowerCase();

// Used in Kajabi style app
export const getVideoMinutes = (DurationSeconds: string) => {
  if (DurationSeconds) {
    const seconds = parseInt(DurationSeconds);
    if (seconds > 60) {
      return `${Math.round(seconds / 60)} min`;
    } else {
      return `${seconds} sec`;
    }
  }
  return '';
};

export const getVideoTimestamp = (DurationSeconds: string) => {
  if (DurationSeconds) {
    return secondsToTime(parseInt(DurationSeconds), { hideHours: true });
  }
  return '';
};

export const getVideoValuesFromNewVideo = (video: BuilderVideo) => {
  const newVideo = { ...video };

  return Object.entries(newVideo)
    .map(([key, value]) => {
      return { name: key as keyof Video, value };
    })
    .filter((val) => {
      // Only number and string properties or Resources are saved for new videos
      // Other properties are typically calculated and aren't stored values
      const type = typeof val.value;
      return type === 'number' || type === 'string' || val.name === 'Resources';
    }) as { name: keyof Video; value: number | string | Resource[] }[];
};

export const getFileUrl = (file: BuilderVideo) => {
  return `https://storage.vidapp.com/${file.AppId}/${file.OriginalFilename}`;
};
