import styled from 'styled-components';

import {
  ContentAdvancedSettings,
  ContentSourceTitle,
  Segment,
  SettingsVideoThumbnail,
  VideoDescriptionPreview,
  VideoTitle,
} from 'components';
import { useContent } from 'providers';
import { SOURCE_VIDAPP, Video } from 'api';
import { getDataSourceDisplayName } from 'utils';

import { ContentViewContainer, ItemResources } from './components';
import { VideoDebug } from 'app/modules/content/Content/content-view-screens/components/VideoDebug';

const SEGMENT_MARGIN = { $marginTop: '0px', $marginBottom: '40px' };

const PaddedContainer = styled.div`
  // All children of a ContentCollectionView except for the content tree need to be given extra padding, so tree rows appear to bleed into the margins
  padding: 0 4px 0 6px;
`;

interface ContentVideoViewProps {
  videoId: string | number;
}

export const ContentVideoView = ({ videoId }: ContentVideoViewProps) => {
  const { videos } = useContent();

  const video = videos[videoId] ? (videos[videoId] as Video) : undefined;

  return (
    <ContentViewContainer video={video as Video}>
      {video && (
        <PaddedContainer>
          {video.DataSource !== SOURCE_VIDAPP && (
            <ContentSourceTitle
              size="large"
              DataSource={video.DataSource}
              SourceName={video.SourceTitle}
              showDeepLink
              itemId={video.VideoId}
              itemType="video"
              {...SEGMENT_MARGIN}
            />
          )}
          <VideoTitle
            VideoId={video.VideoId}
            Title={video.Title}
            sourceTitle={video.SourceTitle}
            label="VidApp Title"
            size="large"
            {...SEGMENT_MARGIN}
          />
          <SettingsVideoThumbnail video={video} {...SEGMENT_MARGIN} />
          <VideoDescriptionPreview
            video={video}
            revertText={`Use description from ${getDataSourceDisplayName(video.DataSource)}`}
          />
          {video.Resources && video.Resources.length > 0 && (
            <Segment header="Resources" {...SEGMENT_MARGIN}>
              <ItemResources resources={video.Resources} />
            </Segment>
          )}
          <ContentAdvancedSettings video={video} />
          <VideoDebug video={video} />
        </PaddedContainer>
      )}
    </ContentViewContainer>
  );
};
