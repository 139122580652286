import { useMemo } from 'react';
import styled from 'styled-components';

import { BuilderProduct, useLocalProducts } from 'providers';
import {
  EditableOfferId,
  ExternalTitle,
  FreeTrialPeriod,
  InternalTitle,
  OfferContent,
  OfferDataSource,
  OfferId,
  PurchaseType,
  SubscriptionDuration,
  SubscriptionGroup,
  SubscriptionPrice,
} from '.';
import { SOURCE_VIDAPP } from '../../../../../api';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div<{ $width?: string; $marginLeft?: string }>`
  width: ${({ $width }) => $width};
  flex-grow: ${({ $width }) => ($width ? 0 : 1)};
  margin-left: ${({ $marginLeft }) => $marginLeft};
  display: flex;
  flex-direction: column;
`;

interface OfferDetailsProps {
  productId: number | string;
}

export const OfferDetails = ({ productId }: OfferDetailsProps) => {
  const { products } = useLocalProducts();

  const {
    Id,
    DataSource,
    DisplayName,
    CustomDisplayName,
    ProductType,
    SubscriptionDuration: SubscriptionDurationVal,
    PriceTier,
    SubscriptionTrial,
    SubscriptionGroup: SubscriptionGroupVal,
    SourceProductId,
    ProductId,
    ProductIdAndroid,
    ProductIdWeb,
    AppId,
  } = useMemo(() => products?.get(productId) as BuilderProduct, [products, productId]);

  const isSubscription = ProductType === 'subscription';
  const isBuyToOwn = ProductType === 'purchase';

  return (
    <Wrapper>
      <Column>
        <InternalTitle Id={Id} DisplayName={DisplayName} DataSource={DataSource} AppId={AppId} />
        <ExternalTitle
          Id={Id}
          CustomDisplayName={CustomDisplayName}
          DisplayName={DisplayName}
          placeholder={DisplayName}
        />
        <PurchaseType Id={Id} ProductType={ProductType} />
        {isSubscription && (
          <>
            <SubscriptionDuration Id={Id} SubscriptionDuration={SubscriptionDurationVal} />
            <SubscriptionPrice Id={Id} PriceTier={PriceTier} />
            <FreeTrialPeriod Id={Id} SubscriptionTrial={SubscriptionTrial} />
            <SubscriptionGroup Id={Id} SubscriptionGroup={SubscriptionGroupVal} />
          </>
        )}
        {isBuyToOwn && (
          <>
            <SubscriptionPrice Id={Id} PriceTier={PriceTier} title="Buy To Own Price" />
          </>
        )}
        <OfferContent productId={productId} />
      </Column>
      <Column $width="191px" $marginLeft="30px">
        <OfferDataSource Id={Id} DataSource={DataSource} />
        <EditableOfferId
          Id={Id}
          title="Source Offer ID"
          fieldName="SourceProductId"
          value={SourceProductId}
          DataSource={DataSource}
        />
        <OfferId Id={Id} ProductId={ProductId} />
        {ProductIdAndroid && ProductIdAndroid?.length > 0 && (
          <OfferId Id={Id} title="Offer ID Android" ProductId={ProductIdAndroid} />
        )}
        {DataSource === SOURCE_VIDAPP && (
          <EditableOfferId
            Id={Id}
            title="Offer ID Web (PriceID)"
            fieldName="ProductIdWeb"
            value={ProductIdWeb}
            DataSource={DataSource}
          />
        )}
      </Column>
    </Wrapper>
  );
};
