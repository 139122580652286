import { MouseEvent, MutableRefObject, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import styled, { css } from 'styled-components';

import { APP_FONT_18PX_SEMIBOLD } from 'mockup-font';
import { OVERFLOW_ELLIPSIS } from 'font';
import { Collection } from 'api';

import { BuilderCollection } from 'app/modules/build-dragdrop/Builder/providers';
import { useAppTheme } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { ContentPositionProvider } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import { SUBNAV_HEIGHT } from 'app/modules/build-dragdrop/Builder/mockup/const';

const SubNavSelector = styled.div<{ $bgColor: string; $sticky?: boolean; $hideShadow?: boolean }>`
  padding-left: 10px;
  position: ${({ $sticky }) => ($sticky ? 'sticky' : 'static')};
  top: 0;
  display: flex;
  align-items: center;

  background-color: ${({ $bgColor }) => $bgColor};
  box-shadow: ${({ $hideShadow }) => !$hideShadow && '0 14px 20px rgba(0, 0, 0, 0.04)'};
  z-index: 10;

  width: 100%;
  min-height: ${SUBNAV_HEIGHT}px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

interface SubNavItemProps {
  $selected?: boolean;
  $highlightHEX: string;
  $primaryItemHEX: string;
  $secondaryItemHEX: string;
}

const SubNavItem = styled.div<SubNavItemProps>`
  display: flex;
  align-items: center;

  padding: 6px 0;
  margin-left: 6px;
  margin-right: 6px;

  min-width: fit-content;
  height: 100%;

  color: ${({ $primaryItemHEX, $selected, $secondaryItemHEX }) => ($selected ? $primaryItemHEX : $secondaryItemHEX)};
  opacity: ${({ $selected }) => ($selected ? 1 : 0.7)};
  cursor: pointer;
  ${APP_FONT_18PX_SEMIBOLD};
  ${OVERFLOW_ELLIPSIS};
  ${({ $selected, $highlightHEX }) => {
    return css`
      border-bottom: ${$selected ? `3px ${$highlightHEX ?? 'black'} solid` : '3px transparent solid'};
    `;
  }}
`;

interface Item {
  id: string;
  name: string;
  component: ReactNode;
}

interface SubNavDisplayProps {
  subNav?: Collection | BuilderCollection;
  items?: Item[];
  onChange?: (index: number) => void;
  overrideSelected?: number;
  hideShadow?: boolean;
  sticky?: boolean; // Does it stick while scrolling
}

export const SubNavDisplay = ({ items = [], onChange, overrideSelected, sticky, hideShadow }: SubNavDisplayProps) => {
  const { getDesignProperty } = useAppTheme();

  // Which of the possible items are selected
  const [selected, setSelected] = useState(0);

  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  const handleSubNavClick = (index: number) => {
    setSelected(index);
    if (onChange) {
      onChange(index);
    }
  };
  const handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    events.onMouseDown(event);
    event.stopPropagation();
  };
  // Needs to be ?? otherwise 0 will be ignored
  const selectedIndex = overrideSelected ?? selected;

  const scrollTheSubNav = () => {
    if (ref?.current) {
      const element = ref.current;
      element.style.scrollBehavior = 'smooth';
      // Scroll the subtab into the center horizontally
      // Vertically set to nearest to avoid moving as it should already be in view
      if (selectedIndex === 0) {
        // Bypasses unclear issue where scrolling to index 0 sometimes does nothing
        element.scroll({ left: 0 });
      } else {
        element.children[selectedIndex].scrollIntoView({ inline: 'center', block: 'nearest' });
      }
      element.style.scrollBehavior = 'unset';
    }
  };
  useLayoutEffect(() => {
    // Give the subnav a chance to load before scrolling
    const timeout = setTimeout(() => {
      scrollTheSubNav();
    }, 100);
    return () => clearTimeout(timeout);
  }, [selectedIndex]);

  if (selectedIndex >= items.length) {
    return null;
  }

  return (
    <ContentPositionProvider contentRef={ref} idx={-1}>
      <SubNavSelector
        ref={ref}
        onMouseDown={handleMouseDown}
        $bgColor={getDesignProperty('backgroundHEX') as string}
        $sticky={sticky}
        $hideShadow={hideShadow}
      >
        {items.map((item, index) => {
          return (
            <SubNavItem
              key={item.id}
              onClick={() => handleSubNavClick(index)}
              $selected={selectedIndex === index}
              $highlightHEX={getDesignProperty('highlightHEX') as string}
              $primaryItemHEX={getDesignProperty('primaryItemHEX') as string}
              $secondaryItemHEX={getDesignProperty('secondaryItemHEX') as string}
            >
              {item.name}
            </SubNavItem>
          );
        })}
      </SubNavSelector>
      {items[selectedIndex].component}
    </ContentPositionProvider>
  );
};
