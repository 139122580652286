import styled from 'styled-components';
import generatePicker from 'antd/es/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { RangeValue } from 'rc-picker/lib/interface';
import { subDays, subWeeks, startOfWeek, endOfWeek, subMonths, startOfMonth, endOfMonth } from 'date-fns';
import { HIGHLIGHT_PRIMARY_COLOUR } from 'theme';

const DatePicker = styled(generatePicker<Date>(dateFnsGenerateConfig).RangePicker)`
  #react-app && {
    margin: 18px 0 16px 0;
    & td,
    & th {
      border: none;
    }
    & .ant-picker-preset {
      margin: 0;
      .ant-tag {
        margin-right: 7px;
      }
    }
    & .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    & .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      color: #fff;
      background-color: ${HIGHLIGHT_PRIMARY_COLOUR};
    }
    & .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    & .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    & .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      background-color: #e6f7ff;
    }
    & .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
      left: 50%;
    }
    & .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
      right: 50%;
    }
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
    &
      .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
    &
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
        .ant-picker-cell-range-hover-edge-start-near-range
      )::after,
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
      left: 6px;
      border-left: 1px dashed #7ec1ff;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    &
      .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
    &
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
        .ant-picker-cell-range-hover-edge-end-near-range
      )::after,
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
      right: 6px;
      border-right: 1px dashed #7ec1ff;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    &
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end)::after,
    &
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end)::after,
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    &
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    &
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    & .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
      position: absolute;
      top: 50%;
      z-index: 0;
      height: 24px;
      border-top: 1px dashed #7ec1ff;
      border-bottom: 1px dashed #7ec1ff;
      transform: translateY(-50%);
      content: '';
    }
  }
`;

const earliestAvailableDate = new Date(2021, 9, 1);

export interface DateRangePickerProps {
  today: Date;
  onChange: (values: RangeValue<Date>) => void;
  value: [Date, Date];
  disabled: boolean;
  allowClear: boolean;
}

export const DateRangePicker = ({ today, onChange, value, disabled, allowClear }: DateRangePickerProps) => (
  <DatePicker
    ranges={{
      All: [earliestAvailableDate, today],
      Today: [today, today],
      Yesterday: [subDays(today, 1), subDays(today, 1)],
      'Last Week': [
        startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
        endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }),
      ],
      'Last Month': [startOfMonth(subMonths(today, 1)), endOfMonth(subMonths(today, 1))],
      'Last 7 Days': [subDays(today, 7), subDays(today, 1)],
      'Last 30 Days': [subDays(today, 30), subDays(today, 1)],
    }}
    onChange={onChange}
    value={value}
    disabledDate={(current) => {
      return current < earliestAvailableDate || current > today;
    }}
    getPopupContainer={(triggerNode) => triggerNode}
    disabled={disabled}
    allowClear={allowClear}
  />
);
