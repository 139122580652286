import { useCallback } from 'react';
import { AppProperties } from 'api';
import { useLocalAppProperties } from 'providers';

const DESIGN_DEFAULTS = {
  common: {
    landscapeAspectRatio: 16 / 9,
    padding: 16,
    cornerRadius: 4,
    highlightHEX: '#DB573C',
    errorHEX: '#FF4242',
    completedHEX: '#76C282',
    scheduledHEX: '#518CFD',
    missedHEX: '#FF4242',
    transparentHEX: '#00000007',
    navBarBackgroundHEX: '#FEFEFE',
    navBarTitleHEX: '#484848',
    circuitNavBackgroundHEX: '#666666',
    circuitNavTintHEX: '#ffffff',
    circuitStartButtonHEX: '#68B84D',
    circuitNextButtonHEX: '#518CFD',
    circuitCancelButtonHEX: '#FF4242',
    circuitFinishButtonHEX: '#68B84D',
    circuitRestTimerHEX: '#183D9D',
    circuitProgressFilledHEX: '#68B84D',
    circuitProgressUnfilledHEX: '#ffffff',
    circuitErrorItemHex: '#ff4242',
    circuitPrimaryItemHEX: '#FEFEFE',
    circuitSecondaryItemHEX: '#c0c0c0',
    actionTintColor: '#484848',
    actionTintColorDarkMode: '#ababab',
  },
  light: {
    theme: 'light',
    backgroundHEX: '#FEFEFE',
    primaryElevationHEX: '#FEFEFE',
    secondaryElevationHEX: '#F8F8F8',
    backgroundItemHEX: '#CECECE',
    disabledItemHEX: '#CECECE',
    secondaryItemHEX: '#A1A1A1',
    primaryItemHEX: '#484848',
    tabBarBackgroundHEX: '#F2F2F2',
    tabBarTintHEX: '#F8F8F8',
    workoutNavBackgroundHEX: '#171717',
    workoutNavTintHEX: '#FFFFFF',
    instructionBtnBackgroundHEX: '#F8F8F8',
    instructionBtnTintHEX: '#484848',
    searchInputHEX: '#484848',
    filterButtonBackgroundHEX: '#FFFFFF',
  },
  dark: {
    theme: 'dark',
    backgroundHEX: '#000000',
    primaryElevationHEX: '#171717',
    secondaryElevationHEX: '#484848',
    backgroundItemHEX: '#666666',
    disabledItemHEX: '#666666',
    secondaryItemHEX: '#CECECE',
    primaryItemHEX: '#FEFEFE',
    tabBarBackgroundHEX: '#000000',
    tabBarTintHEX: '#484848',
    workoutNavBackgroundHEX: '#FFFFFF',
    workoutNavTintHEX: '#171717',
    instructionBtnBackgroundHEX: '#484848',
    instructionBtnTintHEX: '#F8F8F8',
    searchInputHEX: '#CECECE',
    filterButtonBackgroundHEX: '#484848',
  },
};

export const getDefaultDesign = (darkMode: boolean) => {
  if (darkMode) {
    return { ...DESIGN_DEFAULTS.dark, ...DESIGN_DEFAULTS.common };
  } else {
    return { ...DESIGN_DEFAULTS.light, ...DESIGN_DEFAULTS.common };
  }
};

const getProperty = (name: string, properties: AppProperties) => {
  switch (name) {
    case 'backgroundHEX':
      return properties?.BackgroundHEX ?? properties?.CustomBackgroundHEX;
    case 'primaryItemHEX':
      return properties?.PrimaryItemHEX;
    case 'secondaryItemHEX':
      return properties?.SecondaryItemHEX;
    case 'primaryElevationHEX':
      return properties?.PrimaryElevationHEX;
    case 'secondaryElevationHEX':
      return properties?.SecondaryElevationHEX;
    case 'tabBarBackgroundHEX':
      return properties?.TabBarBackgroundHEX;
    case 'tabBarTintHEX':
      return properties?.TabBarTintHEX;
    case 'highlightHEX':
      return properties?.HighlightHEX;
    case 'navBarBackgroundHEX':
      return properties?.NavBarBackgroundHEX;
    case 'navBarTitleHEX':
      return properties?.NavBarTitleHEX;
    case 'scheduledHEX':
      return properties?.ScheduledHEX;
    case 'completedHEX':
      return properties?.WatchedHEX;
    case 'journalHEX':
      return properties?.JournalHEX;
    case 'missedHEX':
      return properties?.MissedHEX;
    case 'circuitNavBackgroundHEX':
      return properties?.CircuitNavBarBackgroundHEX;
    case 'circuitNavTintHEX':
      return properties?.CircuitNavBarTintHEX;
    case 'circuitStartButtonHEX':
      return properties?.CircuitStartButtonHEX;
    case 'circuitNextButtonHEX':
      return properties?.CircuitNextButtonHEX;
    case 'circuitCancelButtonHEX':
      return properties?.CircuitCancelButtonHEX;
    case 'circuitFinishButtonHEX':
      return properties?.CircuitFinishButtonHEX;
    case 'circuitRestTimerHEX':
      return properties?.CircuitRestTimerHEX;
    case 'circuitProgressFilledHEX':
      return properties?.CircuitProgressFilledHEX;
    case 'circuitProgressUnfilledHEX':
      return properties?.CircuitProgressUnfilledHEX;
    case 'StartWorkoutButtonHEX':
      return properties?.StartWorkoutButtonHEX;
    case 'FinishWorkoutButtonHEX':
      return properties?.FinishWorkoutButtonHEX;
    case 'WorkoutWayfindingHEX':
      return properties?.WorkoutWayfindingHEX;
    case 'EndWorkoutButtonHEX':
      return properties?.EndWorkoutButtonHEX;
    case 'DownloadWorkoutButtonHEX':
      return properties?.DownloadWorkoutButtonHEX;
    case 'NavBarTitleImage':
      return properties?.NavBarTitleImage;
    default:
      return undefined;
  }
};

export const useAppTheme = () => {
  const { properties } = useLocalAppProperties();
  // Force Light Mode for now
  const defaults = getDefaultDesign(properties?.ColorScheme === 'Dark' ?? false) as Record<string, string | number>;

  const getDesignProperty = useCallback(
    (name: string) => {
      const property = (properties && getProperty(name, properties)) || defaults[name];
      // Ensure hex property is prefixed with #
      return name.includes('HEX') && !(property as string)?.startsWith('#') ? `#${property}` : property;
    },
    [properties],
  );

  return { getDesignProperty };
};
