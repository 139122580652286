import { ReactNode, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Root, Trigger, Content } from '@radix-ui/react-collapsible';

import { FilledRightArrow, ForwardIcon } from 'icons';
import { NEUTRAL_6_COLOUR, NEUTRAL_7_COLOUR } from 'theme';

const slideDown = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
`;

const slideUp = keyframes`
from {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
`;

const CollapseRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTrigger = styled(Trigger)<{ $iconAtEnd?: boolean; $fullWidth?: boolean }>`
  /* Reset */
  all: unset;

  display: flex;
  ${({ $fullWidth }) => $fullWidth && 'width: 100%'};
  flex-direction: ${({ $iconAtEnd }) => ($iconAtEnd ? 'row-reverse' : 'row')};
  align-items: center;
  cursor: pointer;

  :focus {
    outline: none;
  }
  :disabled {
    cursor: not-allowed;
    svg {
      color: ${NEUTRAL_6_COLOUR};
    }
  }
`;

const TreeArrow = styled(FilledRightArrow)<{ $open: boolean; $treeStyle?: boolean; $iconAtEnd?: boolean }>`
  &&&& {
    width: ${({ $treeStyle }) => ($treeStyle ? '40px' : '18px')};
    height: ${({ $treeStyle }) => ($treeStyle ? '40px' : '18px')};
    margin: ${({ $treeStyle, $iconAtEnd }) => ($treeStyle ? 0 : $iconAtEnd ? '0 0 0 4px' : '0 4px 0 0')};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    svg {
      transform: ${({ $open }) => `rotate(${$open ? 90 : 0}deg)`};
      transition: transform 0.24s;
    }
  }
`;

const Chevron = styled(ForwardIcon)<{ $open: boolean; $iconAtEnd?: boolean }>`
  &&&& {
    margin: ${({ $iconAtEnd }) => ($iconAtEnd ? '0 0 0 4px' : '0 4px 0 0')};
    color: ${NEUTRAL_7_COLOUR};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    svg {
      transform: ${({ $open }) => `rotate(${$open ? 90 : 0}deg)`};
      transition: transform 0.24s;
    }
  }
`;

const StyledContent = styled(Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 240ms ease-out;
  }

  &[data-state='closed'] {
    animation: ${slideUp} 240ms ease-out;
  }
`;

interface CollapseProps {
  triggerContent?: ReactNode;
  rowContent?: ReactNode;
  children: ReactNode;
  onChange?: () => void;
  isOpen?: boolean; // Used to control open state externally
  defaultOpen?: boolean;
  hideTrigger?: boolean;
  disableTrigger?: boolean;
  fullWidthTrigger?: boolean;
  treeStyle?: boolean;
  iconAtEnd?: boolean;
  chevronIcon?: boolean;
  $marginLeft?: string;
  className?: string;
}

export const Collapse = ({
  triggerContent,
  rowContent,
  children,
  onChange,
  isOpen,
  defaultOpen,
  hideTrigger,
  disableTrigger,
  fullWidthTrigger,
  treeStyle,
  iconAtEnd,
  chevronIcon,
  ...props
}: CollapseProps) => {
  const [open, setOpen] = useState(defaultOpen ?? false);

  useEffect(() => {
    if (typeof isOpen !== 'undefined') {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    onChange && onChange();
  };

  return (
    <Root open={open} onOpenChange={onOpenChange} {...props}>
      <CollapseRow className="radix-collapse-row">
        {iconAtEnd && rowContent}
        {!hideTrigger && (
          <StyledTrigger disabled={disableTrigger} $iconAtEnd={iconAtEnd} $fullWidth={fullWidthTrigger}>
            {chevronIcon ? (
              <Chevron $open={open} $iconAtEnd={iconAtEnd} />
            ) : (
              <TreeArrow $open={open} $treeStyle={treeStyle} $iconAtEnd={iconAtEnd} />
            )}
            {triggerContent}
          </StyledTrigger>
        )}
        {!iconAtEnd && rowContent}
      </CollapseRow>
      <StyledContent className="radix-collapse-content">{children}</StyledContent>
    </Root>
  );
};
