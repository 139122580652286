import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { SegmentTitle, SettingsTextInput } from 'components';
import { DataSource, FEATURED_SOURCE_ID, SOURCE_TYPE_COLLECTION, SOURCE_VIDAPP } from 'api';
import { getCollectionName, getSourceTypeDisplayName, groupCollectionsBySourceType } from 'utils';
import { BuilderCollection, useContent, useDataSource } from 'providers';
import { SearchIcon } from 'icons';
import { useAppProperties } from 'hooks';

import { AddCollection, DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { CMS_PROPERTY, STANDALONE_PROPERTY } from 'app/modules/build-dragdrop/Builder/const';
import { VariableSizeList } from 'react-window';

const SidePaddedDiv = styled.div`
  padding: 0 28px;
`;

const StyledSearch = styled(SettingsTextInput)`
  &&&.ant-input-affix-wrapper {
    margin-bottom: 24px !important;
  }

  color: ${NEUTRAL_7_COLOUR};
  flex-shrink: 0;
`;

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 16px;
  color: ${NEUTRAL_7_COLOUR} !important;
`;

const EmptyResults = styled.div`
  padding: 134px 28px 0 28px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
`;
const EmptySubtitle = styled.div`
  padding: 0 26px;
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_14PX_REGULAR};
`;

const TitleWrapper = styled.div`
  padding-left: 8px;
`;

const Padder = styled.div<{ $padding: number }>`
  padding-top: ${({ $padding }) => $padding};
`;

export const AddContent = () => {
  const [query, setQuery] = useState<string>('');
  const { isAddPanelEnabled, visiblePageId } = useBuilderContext();
  const { data } = useAppProperties();
  const datasource = useDataSource();
  const [listHeight, setListHeight] = useState<number>(window.innerHeight - 330);
  const listRef = useRef();
  const handleResize = () => {
    setListHeight(window.innerHeight - 328);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize, { passive: true });
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  // Returns true if the collection is available in the toolbox
  const isValidCollection = useCallback(
    (collection: BuilderCollection) => {
      if (collection.DataSource === SOURCE_VIDAPP && data?.[CMS_PROPERTY] !== '1') {
        return false;
      } else if (collection.SourceId === FEATURED_SOURCE_ID) {
        return false;
      } else if (collection.IsMainTab === 1 || (collection?.Position || 0) > 0) {
        // No Tabs
        return false;
      } else if (collection.Published && collection.Published !== 'Published') {
        // No draft collections
        return false;
      } else if (visiblePageId === collection.TabId) {
        // Can't add tab to itself
        return false;
      } else if (collection.DataSource === SOURCE_VIDAPP && collection.SourceType !== SOURCE_TYPE_COLLECTION) {
        // Only CMS type VidApp collections can be added to collections
        // This will exclude navigational elements and websites/textimage
        return false;
      }
      // Filter down by search query (empty query always returns true)
      const lowerQuery = query?.toLowerCase();
      return (
        getCollectionName(collection)?.toLowerCase().includes(lowerQuery) || collection.SourceId.includes(lowerQuery)
      );
    },
    [data, visiblePageId, query],
  );

  const { collections } = useContent();
  const contentBySourceType = useMemo(() => {
    // Exclude Main Tabs from being available to drag and drop
    // Exclude Draft Content from being available
    const collectionArray = Object.values(collections ?? {})
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((collection: BuilderCollection) => isValidCollection(collection));
    return groupCollectionsBySourceType(collectionArray);
  }, [collections, isValidCollection]);

  const virtualItems = useMemo(() => {
    const arr: { id: string; heading?: string; collection?: BuilderCollection; padding?: number }[] = [];
    for (const [heading, cs] of contentBySourceType) {
      arr.push({
        id: `heading_${heading}`,
        heading: getSourceTypeDisplayName(heading, datasource as DataSource, {
          pluralize: true,
          defaultName: 'Other',
        }),
      });
      for (const c of cs) {
        arr.push({ id: `collection_${c.TabId}`, collection: c });
      }
      // Add spacing after each group
      arr.push({ padding: 40, id: `padding_${heading}` });
    }

    return arr;
  }, [contentBySourceType]);

  // Reset cached heights of items
  useEffect(() => {
    // @ts-ignore
    listRef?.current?.resetAfterIndex(0);
  }, [virtualItems]);

  const getItemSize = useCallback(
    (index: number) => {
      const item = virtualItems[index];
      if (!!item.heading) {
        return 38;
      } else if (!!item.padding) {
        return item.padding;
      }
      return 61;
    },
    [virtualItems],
  );
  const getItemKey = useCallback(
    (index: number) => {
      const item = virtualItems[index];
      return item.id;
    },
    [virtualItems],
  );

  return (
    <>
      <SidePaddedDiv>
        <StyledSearch
          width="100%"
          prefix={<StyledSearchIcon />}
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
          disabled={!isAddPanelEnabled}
          size="middle"
        />
      </SidePaddedDiv>
      <DrawerScrollContainer paddingLeft="20px" paddingBottom="0">
        {virtualItems?.length > 0 ? (
          <VariableSizeList
            ref={listRef as any} //eslint-disable-line
            height={listHeight}
            width={322}
            itemSize={getItemSize}
            itemData={virtualItems}
            itemCount={virtualItems.length}
            itemKey={getItemKey}
          >
            {({ index, style }) => {
              const item = virtualItems[index];
              const { padding, collection, heading } = item;
              if (padding) {
                return <Padder $padding={padding} key={item.id} />;
              }

              return collection ? (
                <AddCollection collection={collection} index={index} key={item.id} style={style} />
              ) : (
                <TitleWrapper style={style} key={item.id}>
                  <SegmentTitle title={heading ?? 'Collections'} />
                </TitleWrapper>
              );
            }}
          </VariableSizeList>
        ) : (
          <EmptyResults>
            {!!query ? (
              <>
                <div>
                  No results found for
                  <br />"{query}"
                </div>
                <EmptySubtitle>Check the spelling or try a different search term.</EmptySubtitle>
              </>
            ) : (
              <>
                You have no groups yet.
                <EmptySubtitle>
                  {data?.[STANDALONE_PROPERTY] === '1' ? (
                    'Start with Sections?'
                  ) : (
                    <span>
                      Your content may still
                      <br />
                      be syncing
                    </span>
                  )}
                </EmptySubtitle>
              </>
            )}
          </EmptyResults>
        )}
      </DrawerScrollContainer>
    </>
  );
};
