import styled from 'styled-components';

import { CustomButton, PageContainer } from 'components';
import { useMFFModules } from 'hooks';
import { useLocalAppProperties, useSaveContext, useUnsavedChanges } from 'providers';
import { PAGE_CONTAINER_MIDDLE_WIDTH } from 'theme';

import { ModuleCard } from './components/ModuleCard';

const ModuleContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const MFFModules = () => {
  const { data: modules, isLoading: modulesIsLoading, isError: modulesIsError } = useMFFModules();
  const { properties } = useLocalAppProperties();
  const { isSaving, saveChanges } = useSaveContext();
  const { unsavedChanges } = useUnsavedChanges();

  return (
    <>
      <PageContainer
        heading="Modules"
        subheading="Configure an app’s Modules"
        headingButton={
          <CustomButton medium onClick={saveChanges} loading={isSaving} disabled={!unsavedChanges}>
            Save
          </CustomButton>
        }
        contentMaxWidth={PAGE_CONTAINER_MIDDLE_WIDTH}
        isLoading={modulesIsLoading || !properties}
        isError={modulesIsError}
      >
        <ModuleContainer>
          {modules?.map((module) => (
            <ModuleCard key={module.ID} module={module} />
          ))}
        </ModuleContainer>
      </PageContainer>
    </>
  );
};
