import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_PRIMARY_ACTIVE_COLOUR,
  HIGHLIGHT_SECONDARY_HOVER_COLOUR,
  HIGHLIGHT_SECONDARY_ACTIVE_COLOUR,
  NEUTRAL_1_COLOUR,
  NEUTRAL_6_COLOUR,
  NEUTRAL_9_COLOUR,
} from 'theme';
import { FONT_14PX_MEDIUM } from 'font';

export const CsvButton = styled(CSVLink)`
  #react-app && {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: none;
    width: 167px;
    height: 30px;
    margin-top: 5px;
    ${FONT_14PX_MEDIUM};
    background-color: ${NEUTRAL_1_COLOUR};
    color: ${NEUTRAL_9_COLOUR};
    border-color: ${NEUTRAL_6_COLOUR};

    :hover,
    :focus {
      background-color: ${HIGHLIGHT_SECONDARY_HOVER_COLOUR};
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
      border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
    }

    :active {
      background-color: ${HIGHLIGHT_SECONDARY_ACTIVE_COLOUR};
      color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
      border-color: ${HIGHLIGHT_PRIMARY_ACTIVE_COLOUR};
    }

    :disabled {
      background-color: ${NEUTRAL_1_COLOUR};
      color: ${NEUTRAL_6_COLOUR};
      border-color: ${NEUTRAL_6_COLOUR};
    }

    svg {
      margin-right: 10px;
    }
  }
`;
