import { useCallback } from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';

import { useLocalAppProperties } from 'providers';
import { CustomColor, useCustomColor, TintImage, isUrl, getUserEmail, getUserName } from 'utils';
import { useAppBeingEdited } from 'app-context';
import { S3_BUCKET } from 'api';
import {
  APP_FONT_13PX_MEDIUM,
  APP_FONT_15PX_MEDIUM,
  APP_FONT_15PX_REGULAR,
  APP_FONT_17PX_REGULAR,
  APP_FONT_20PX_MEDIUM,
} from 'mockup-font';
import { ChevronRightIcon, ProfileIcon } from 'icons';

import { useAppTheme } from '../../hooks';

interface CoreStat {
  Title: string;
  Icon: string;
}

const Wrapper = styled.div<{ $backgroundColor: string } & CustomColor>`
  width: 100%;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 44px 17px;
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Circle = styled.div<{ $highlightHEX: string } & CustomColor>`
  width: 79px;
  height: 79px;
  border-radius: 50%;
  background-color: ${useCustomColor};
  margin-bottom: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  :after {
    content: '+';
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #fff;
    background-color: ${({ $highlightHEX }) => $highlightHEX};
    font-size: 18px;
    padding: 0 0 3px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledProfileIcon = styled(ProfileIcon)`
  &&&& {
    font-size: 46px;
    color: #fff;
  }
`;

const UserName = styled.div`
  ${APP_FONT_20PX_MEDIUM};
  margin-bottom: 4px;
`;

const UserEmail = styled.div`
  ${APP_FONT_15PX_REGULAR};
`;

const CoreStatsSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
`;

const CoreStatWrapper = styled.div`
  width: 113px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${APP_FONT_20PX_MEDIUM};
  text-align: center;
  margin-bottom: 17px;
`;

const CoreStatIcon = styled.div`
  width: 31px;
  height: 31px;
  margin-bottom: 9px;
`;

const CoreStatTitle = styled.div`
  ${APP_FONT_13PX_MEDIUM};
  width: 100%;
  margin-top: 4px;
  overflow: hidden;
`;

const Divider = styled.div`
  border-top: 1px solid #cecece;
  margin: 35px 0 9px;
`;

const Heading = styled.div<CustomColor>`
  color: ${useCustomColor};
  ${APP_FONT_15PX_MEDIUM};
  margin-top: 44px;
`;

const Row = styled.div`
  width: 100%;
  height: 61px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${APP_FONT_17PX_REGULAR};

  svg {
    font-size: 32px;
  }
`;

const Toggle = styled(Switch)<CustomColor>`
  &&&& {
    width: 56px;
    height: 36px;
    border-radius: 33px;
    box-shadow: none;
    background-color: ;
    background-color: ${({ checked }) => (checked ? '#60bc57' : useCustomColor)};
    opacity: 1;

    .ant-switch-handle {
      top: 3px;
      left: ${({ checked }) => (checked ? '24px' : '3px')};
      width: 29px;
      height: 29px;

      ::before {
        border-radius: 50%;
      }
    }
  }
`;

const SignOut = styled.div<{ $borderColor: string }>`
  margin: 52px auto 0;
  width: 143px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${APP_FONT_15PX_MEDIUM};
  border: ${({ $borderColor }) => `2px solid ${$borderColor}`};
  border-radius: 4px;
`;

const Spacer = styled.div`
  height: 70px;
`;

export const ProfileView = () => {
  const appId = useAppBeingEdited();
  const { properties } = useLocalAppProperties();
  const { getDesignProperty } = useAppTheme();
  const HighlightHEX = getDesignProperty('highlightHEX') as string;
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const SecondaryItemHEX = getDesignProperty('secondaryItemHEX') as string;
  const BackgroundItemHEX = getDesignProperty('backgroundItemHEX') as string;
  const BackgroundHEX = getDesignProperty('backgroundHEX') as string;
  const DisabledItemHEX = getDesignProperty('disabledItemHEX') as string;

  const coreStatsConfig = properties.CoreStats ? JSON.parse(properties.CoreStats) : { Enabled: '0' };
  const coreStatsIsEnabled = properties.DisplayCoreStats === '1' && coreStatsConfig.Enabled === '1';
  const coreStats: CoreStat[] = coreStatsConfig ? coreStatsConfig.Stats : [];

  const getIconUrl = useCallback(
    (filename: string) => (isUrl(filename) ? filename : `${S3_BUCKET}${appId}/icons/${filename}`),
    [appId],
  );

  return (
    <Wrapper $color={PrimaryItemHEX} $backgroundColor={BackgroundHEX}>
      <UserSection>
        <Circle $color={BackgroundItemHEX} $highlightHEX={HighlightHEX}>
          <StyledProfileIcon />
        </Circle>
        <UserName>{getUserName() ?? 'John Smith'}</UserName>
        <UserEmail>{getUserEmail() ?? 'johnsmith@email.com'}</UserEmail>
      </UserSection>
      {coreStatsIsEnabled && (
        <CoreStatsSection>
          {coreStats.map((stat, idx) => (
            <CoreStatWrapper key={idx}>
              <CoreStatIcon>
                {stat.Icon && (
                  <TintImage src={getIconUrl(stat.Icon)} color={HighlightHEX} dimensions={{ width: 31, height: 31 }} />
                )}
              </CoreStatIcon>
              {idx === 0 ? '3' : idx === 1 ? '2' : '54'}
              <CoreStatTitle>{stat.Title}</CoreStatTitle>
            </CoreStatWrapper>
          ))}
        </CoreStatsSection>
      )}
      <Divider />

      <Heading $color={SecondaryItemHEX}>Settings</Heading>
      {[
        { label: 'Enable My Core Stats', isEnabled: true, isHidden: !coreStatsIsEnabled },
        { label: 'Auto play', isEnabled: true },
        { label: 'Only download via wifi', isEnabled: true },
        { label: 'Continue video in background' },
        { label: 'Allow background audio from other apps' },
      ].map(
        ({ label, isEnabled, isHidden }) =>
          !isHidden && (
            <Row key={label}>
              {label}
              <Toggle checked={isEnabled} disabled $color={DisabledItemHEX} />
            </Row>
          ),
      )}
      <Row>
        Download quality
        <ChevronRightIcon />
      </Row>
      <Heading $color={SecondaryItemHEX}>Other</Heading>
      {['Terms & Conditions', 'Privacy Policy', 'Support', 'Request Data Deletion'].map((label) => (
        <Row key={label}>
          {label}
          <ChevronRightIcon />
        </Row>
      ))}
      <SignOut $borderColor={DisabledItemHEX}>Sign Out</SignOut>
      <Spacer />
    </Wrapper>
  );
};
