import styled from 'styled-components';

import { MFFModule } from 'api';
import { SettingsSelectInput } from 'components';
import { FONT_12PX_REGULAR, FONT_12PX_SEMIBOLD } from 'font';
import { useLocalAppProperties, useSaveContext, useUnsavedChanges } from 'providers';
import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

const ENABLED_MODULES_PROPERTY = 'EnabledModules';
const MODULE_ACCESS = [
  { name: 'Disabled', value: 0 },
  { name: 'Preview', value: 2 },
  { name: 'Enabled', value: 1 },
];

const Card = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 8px;
  padding: 20px;
`;
const Details = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
  color: ${NEUTRAL_8_COLOUR};
`;
const VersionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
const Version = styled.div`
  margin-right: 20px;
  ${FONT_12PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
  span {
    ${FONT_12PX_SEMIBOLD};
    color: ${NEUTRAL_10_COLOUR};
  }
`;

export const ModuleCard = ({ module }: { module: MFFModule }) => {
  const { ID, Name, Description, MinNative, MinReact } = module;
  const { properties, setAppProperty } = useLocalAppProperties();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const { setAppPropertyToSave } = useSaveContext();

  const enabledModules: Record<string, number> = properties[ENABLED_MODULES_PROPERTY]
    ? !Array.isArray(properties[ENABLED_MODULES_PROPERTY])
      ? JSON.parse(properties[ENABLED_MODULES_PROPERTY])
      : {}
    : {};

  const currentAccess = ID in enabledModules ? enabledModules[ID] : 0;

  const handleChange = (option: number) => {
    if (!unsavedChanges) {
      setUnsavedChanges(true);
    }

    if (option === 0) {
      delete enabledModules[ID];
    } else enabledModules[ID] = option;

    const valueToSave = JSON.stringify(enabledModules);
    setAppProperty(ENABLED_MODULES_PROPERTY, valueToSave);
    setAppPropertyToSave(ENABLED_MODULES_PROPERTY, valueToSave);
  };

  return (
    <Card>
      <SettingsSelectInput
        label={Name}
        options={MODULE_ACCESS}
        defaultVal={currentAccess}
        onChange={(option) => handleChange(option)}
      />
      <Details>{Description}</Details>
      <VersionWrapper>
        <Version>
          <span>Min. Native Version:</span> {MinNative}
        </Version>
        <Version>
          <span>Min. React Version:</span> {MinReact}
        </Version>
      </VersionWrapper>
    </Card>
  );
};
