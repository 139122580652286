import { AxiosInstance } from 'axios';

const URL = 'https://onesignal.com/api/v1/notifications';

interface GetNotificationsResponse {
  notifications: OneSignalNotification[];
}

export interface OneSignalNotification {
  id: string;
  headings: { en: string };
  contents: { en: string };
  send_after: number;
  completed_at?: number;
  canceled: boolean;
  delayed_option: 'immediate' | 'last-active' | 'timezone';
  delivery_time_of_day?: string;
  data?: { deep_linking_url: string };
  url: string;
  included_segments: string[];
  successful: number;
  converted: number;
  include_aliases?: { external_id: string[] } | null;
}

export interface CreateOneSignalNotificationOptions {
  headings: string;
  contents: string;
  sendAfter: string;
  delayedOption?: 'last-active' | 'timezone';
  deliveryTimeOfDay?: string;
  deepLinkingUrl?: string;
  includedSegments?: string[];
}

interface OneSignalSegment {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  app_id: string;
  read_only: boolean;
  is_active: boolean;
}

interface GetSegmentsResponse {
  total_count: number;
  offset: number;
  limit: number;
  segments: OneSignalSegment[];
}

export const getOneSignalNotifications = (client: AxiosInstance, oneSignalAppId?: string, oneSignalKey?: string) => {
  if (oneSignalAppId && oneSignalKey) {
    return client
      .get<GetNotificationsResponse>(`${URL}?app_id=${oneSignalAppId}`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Basic ${oneSignalKey}`,
        },
      })
      .then(({ data }) => data.notifications.sort((a, b) => b.send_after - a.send_after));
  }
  return [];
};

export const createOneSignalNotification = async (
  client: AxiosInstance,
  options: CreateOneSignalNotificationOptions,
  oneSignalAppId?: string,
  oneSignalKey?: string,
) => {
  if (oneSignalAppId && oneSignalKey) {
    const body: Record<string, unknown> = {
      app_id: oneSignalAppId,
      included_segments: options.includedSegments ?? ['All'],
      headings: { en: options.headings },
      contents: { en: options.contents },
      content_available: true,
      send_after: options.sendAfter,
      ios_badgeType: 'SetTo',
      ios_badgeCount: 1,
      ttl: 86400,
    };
    if (options.delayedOption) {
      body.delayed_option = options.delayedOption;
      if (options.delayedOption === 'timezone' && options.deliveryTimeOfDay) {
        body.delivery_time_of_day = options.deliveryTimeOfDay;
      }
    }
    if (options.deepLinkingUrl) {
      body.data = { deep_linking_url: options.deepLinkingUrl }; // This can be removed once all apps have been updated to 3.0+, as body.url is used instead
      body.url = options.deepLinkingUrl;
    }

    return client.post(`${URL}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${oneSignalKey}`,
      },
    });
  }
  return null;
};

export const cancelOneSignalNotification = async (
  client: AxiosInstance,
  notificationId: string,
  oneSignalAppId?: string,
  oneSignalKey?: string,
) => {
  if (oneSignalAppId && oneSignalKey) {
    return client.delete(`${URL}/${notificationId}?app_id=${oneSignalAppId}`, {
      headers: {
        Authorization: `Basic ${oneSignalKey}`,
      },
    });
  }
  return null;
};

export const getOneSignalSegments = (client: AxiosInstance, oneSignalAppId?: string, oneSignalKey?: string) => {
  if (oneSignalAppId && oneSignalKey) {
    return client
      .get<GetSegmentsResponse>(`https://api.onesignal.com/apps/${oneSignalAppId}/segments`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Basic ${oneSignalKey}`,
        },
      })
      .then(({ data }) => data.segments);
  }
  return null;
};
