import { useMemo } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

import {
  getContentName,
  getContentResourceType,
  getSourceTypeDisplayName,
  getVideoTimestamp,
  isContentResource,
  isSectionHeader,
} from 'utils';
import { BuilderCollection, BuilderCollectionItem, BuilderVideo } from 'providers';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { NEUTRAL_7_COLOUR } from 'theme';
import { ContentIcon } from 'components';

import { ITEM_TYPE_VIDEO, TEXT_IMAGE_TEMPLATE, Video, VIDEO_TYPE_POST, VIDEO_TYPE_REST } from 'api';

import { ContentThumbnail } from '.';

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: clip;
  margin-bottom: 0;
  flex-grow: 1;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  overflow: hidden;
`;
const ItemName = styled(Typography.Paragraph)`
  &&&& {
    ${FONT_12PX_MEDIUM};
    width: 100%;
    max-width: 100%;
    max-height: 36px;
    margin-bottom: 0;
  }
`;
const TimeStamp = styled.span`
  color: ${NEUTRAL_7_COLOUR};
`;
const ItemSubtitle = styled.div`
  color: ${NEUTRAL_7_COLOUR};
  ${FONT_10PX_MEDIUM};
  display: flex;
  margin-top: 4px;
`;

const SubtitleIcon = styled.div`
  margin-right: 4px;
  font-size: 14px;
`;
const SubtitleText = styled.div`
  max-width: 68px;
  margin-right: 12px;
  ${OVERFLOW_ELLIPSIS};
`;
const StyledThumbnail = styled(ContentThumbnail)`
  width: 80px;
  height: unset;
  aspect-ratio: 16/9;
`;
const SourceId = styled.div`
  max-width: 100px;
  ${OVERFLOW_ELLIPSIS};
`;

interface ContentCardProps {
  content: BuilderVideo | BuilderCollection;
  type: BuilderCollectionItem['Type'];
  hideType?: boolean;
}

/**
 * Displays a summary display of a Collection or Video
 * Used in VidApp Sections and the Add Content modal
 * @param content - Either a Video or a Collection
 * @param type - Either video/collection (item type)
 */
export const ContentCard = ({ content, type, hideType, ...props }: ContentCardProps) => {
  const timestamp = useMemo(() => {
    if (type === ITEM_TYPE_VIDEO) {
      const stamp = getVideoTimestamp((content as Video)?.DurationSeconds ?? '');
      return stamp ? ` (${stamp})` : '';
    }
    return '';
  }, [type, content]);

  const subtitleText = useMemo(() => {
    if (isSectionHeader(content)) {
      return 'Text';
    }
    if (type === ITEM_TYPE_VIDEO || type === VIDEO_TYPE_POST) {
      if (isContentResource(content, type)) {
        return getContentResourceType(content, type)?.toUpperCase();
      }
      return content.Type === VIDEO_TYPE_POST ? 'Post' : content.Type === VIDEO_TYPE_REST ? 'Rest' : 'Video';
    }
    if ((content as BuilderCollection)?.TemplateId === TEXT_IMAGE_TEMPLATE) {
      return 'Page';
    }
    return getSourceTypeDisplayName((content as BuilderCollection)?.SourceType || '', content.DataSource);
  }, [type, content]);

  return (
    <ItemWrapper {...props}>
      <StyledThumbnail thumbnail={content} size="small" defaultBorder="2px whitesmoke solid" overrideRadius="4px" />
      <Column>
        <ItemName ellipsis={{ rows: 2 }}>
          {getContentName(content, type)}
          <TimeStamp>{timestamp}</TimeStamp>
        </ItemName>
        <ItemSubtitle>
          {!hideType && (
            <>
              <SubtitleIcon>
                <ContentIcon
                  itemType={type}
                  contentType={(content as BuilderCollection)?.SourceType ?? (content?.Type || '')}
                  dataSource={(content as BuilderCollection)?.DataSource ?? ''}
                  isFile={isContentResource(content, type)}
                />
              </SubtitleIcon>
              <SubtitleText>{subtitleText}</SubtitleText>
            </>
          )}
          <SourceId>{content?.SourceId || ''}</SourceId>
        </ItemSubtitle>
      </Column>
    </ItemWrapper>
  );
};
