import * as amplitude from '@amplitude/analytics-browser';
import { Form } from 'antd';
import { format, parse } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CreateOneSignalNotificationOptions, OneSignalNotification } from 'api';
import { useAppBeingEdited } from 'app-context';
import { InfoModal } from 'components';
import {
  useAppProperties,
  useCancelOneSignalNotification,
  useCreateOneSignalNotification,
  useOneSignalNotifications,
  useOneSignalSegments,
  useSecureAppProperties,
} from 'hooks';

import { PushNotificationPreviewProps } from '../PushNotifications/components/PushNotificationPreview';

const getHelpText = (delivery: string, delayedOption: string, formattedTime: string, formattedDate: string) => {
  if (delivery === 'now') {
    if (delayedOption === 'immediate') {
      return 'Your message will send right away';
    } else if (delayedOption === 'last-active') {
      return 'Your message will send at the time when each user is predicted to be active';
    } else {
      return `Your message will send the next time it is ${formattedTime}  in each user's timezone`;
    }
  } else {
    if (delayedOption === 'immediate') {
      return `Your message will start sending at ${formattedDate}`;
    } else if (delayedOption === 'last-active') {
      return `Your message will start sending at ${formattedDate} at the time when each user is predicted to be active`;
    } else {
      return `Your message will start sending ${formattedDate} and will send when it is ${formattedTime} in each user's timezone`;
    }
  }
};

export const usePushNotifications = () => {
  const appId = useAppBeingEdited();
  const { data: appProperties, isLoading: appPropertiesIsLoading, isError: appPropertiesIsError } = useAppProperties();
  const {
    data: secureAppProperties,
    isLoading: secureAppPropertiesIsLoading,
    isError: secureAppPropertiesIsError,
  } = useSecureAppProperties();
  const {
    data: oneSignalNotifications,
    isLoading: oneSignalNotificationsIsLoading,
    isError: oneSignalNotificationsIsError,
  } = useOneSignalNotifications();
  const {
    data: oneSignalSegments,
    isLoading: oneSignalSegmentsIsLoading,
    isError: oneSignalSegmentsIsError,
  } = useOneSignalSegments();
  const createOneSignalNotification = useCreateOneSignalNotification();
  const cancelOneSignalNotification = useCancelOneSignalNotification();
  const [form] = Form.useForm();
  const [isComposing, setIsComposing] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<OneSignalNotification | undefined>(undefined);
  const [segmentation, setSegmentation] = useState({ group: 'All', activity: 'Active Users' });

  const readOnly = useMemo(() => {
    if (secureAppProperties) {
      return !secureAppProperties.OneSignalAppId || !secureAppProperties.OneSignalApiKey;
    }
    return true;
  }, [secureAppProperties]);

  const [previewValues, setPreviewValues] = useState<PushNotificationPreviewProps>({
    scheduledTime: format(Date.now(), 'HH:mm'),
    scheduledDate: format(Date.now(), 'EEEE dd MMMM'),
    title: '',
    message: '',
    helpText: 'Your message will send right away',
  });

  const onValuesChange = useCallback(
    (changedValues) => {
      const key = Object.keys(changedValues)[0];

      switch (key) {
        case 'Title':
          setPreviewValues((prevState) => ({ ...prevState, title: changedValues[key] }));
          break;
        case 'Message':
          setPreviewValues((prevState) => ({ ...prevState, message: changedValues[key] }));
          break;
        case 'Delivery':
        case 'DeliveryDate':
        case 'DelayedOption':
        case 'TimeZoneTime':
          const time =
            form.getFieldValue('DelayedOption') === 'timezone'
              ? form.getFieldValue('TimeZoneTime')
              : form.getFieldValue('Delivery') === 'now'
              ? Date.now()
              : form.getFieldValue('DeliveryDate');
          const date = form.getFieldValue('Delivery') === 'now' ? Date.now() : form.getFieldValue('DeliveryDate');
          const formattedTime = format(time, 'HH:mm');
          const formattedDate = format(date, 'EEEE dd MMMM');

          setPreviewValues((prevState) => ({
            ...prevState,
            scheduledTime: formattedTime,
            scheduledDate: formattedDate,
            helpText: getHelpText(
              form.getFieldValue('Delivery'),
              form.getFieldValue('DelayedOption'),
              formattedTime,
              formattedDate,
            ),
          }));
          break;
      }
    },
    [form, setPreviewValues],
  );

  useEffect(() => {
    if (selectedNotification) {
      const time = selectedNotification?.delivery_time_of_day
        ? parse(selectedNotification?.delivery_time_of_day, 'h:mm a', new Date())
        : selectedNotification?.send_after
        ? selectedNotification?.send_after * 1000
        : Date.now();
      const date = selectedNotification ? selectedNotification.send_after * 1000 : Date.now();
      const formattedTime = format(time, 'HH:mm');
      const formattedDate = format(date, 'EEEE dd MMMM');
      setPreviewValues({
        scheduledTime: formattedTime,
        scheduledDate: formattedDate,
        title: selectedNotification.headings.en,
        message: selectedNotification.contents.en,
        helpText: getHelpText(
          'scheduled',
          selectedNotification.delayed_option || 'immediate',
          formattedTime,
          formattedDate,
        ),
      });
    } else {
      setPreviewValues({
        scheduledTime: format(Date.now(), 'HH:mm'),
        scheduledDate: format(Date.now(), 'EEEE dd MMMM'),
        title: '',
        message: '',
        helpText: 'Your message will send right away',
      });
    }
  }, [isComposing, selectedNotification, readOnly]);

  const submitForm = useCallback(async () => {
    if (previewValues.title === '' || previewValues.message === '') {
      const missingField = previewValues.title === '' ? 'title' : 'message';
      InfoModal(
        `Push notifications must include a ${missingField}`,
        `Please enter a ${missingField} and click resend.`,
        'warning',
      );
      return;
    }

    const options: CreateOneSignalNotificationOptions = {
      headings: previewValues.title,
      contents: previewValues.message,
      sendAfter:
        form.getFieldValue('Delivery') === 'scheduled'
          ? format(form.getFieldValue('DeliveryDate'), "yyyy-MM-dd'T'HH:mmxxx")
          : format(Date.now(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
      includedSegments: segmentation.group === 'activity' ? [segmentation.activity] : ['All'],
    };

    if (form.getFieldValue('DelayedOption') && form.getFieldValue('DelayedOption') !== 'immediate') {
      options.delayedOption = form.getFieldValue('DelayedOption');

      if (form.getFieldValue('DelayedOption') === 'timezone') {
        options.deliveryTimeOfDay = format(form.getFieldValue('TimeZoneTime'), 'hh:mm aa');
      }
    }

    if (form.getFieldValue('DeepLink')) {
      options.deepLinkingUrl = form.getFieldValue('DeepLink');
    }

    if (selectedNotification) {
      cancelOneSignalNotification.mutate(selectedNotification.id);
    }

    createOneSignalNotification.mutate(options, {
      onSuccess: () => {
        InfoModal('Success!', 'Your push notification has been published', 'success');
        if (segmentation.group === 'activity') {
          amplitude.track('Segmented PN sent', { appId, segment: segmentation.activity });
        }
        form.resetFields();
        setPreviewValues((prevState) => ({ ...prevState, title: '', message: '' }));
        setSelectedNotification(undefined);
        setIsComposing(false);
      },
      onError: ({ message }) => {
        if (message.includes('All included players are not subscribed')) {
          InfoModal(
            'No users available for the chosen segment',
            'Please choose a different segment and try again',
            'error',
          );
        } else {
          InfoModal('Something went wrong!', 'Please try again or contact support for help', 'error');
        }
      },
    });
  }, [
    amplitude,
    createOneSignalNotification,
    secureAppProperties,
    form,
    previewValues,
    selectedNotification,
    segmentation,
    setSelectedNotification,
    setIsComposing,
    setPreviewValues,
  ]);

  return {
    form,
    readOnly,
    schedulingEnabled: appProperties?.PushNotificationScheduling === '1',
    oneSignalNotifications,
    oneSignalSegments,
    onValuesChange,
    submitForm,
    isComposing,
    setIsComposing,
    selectedNotification,
    setSelectedNotification,
    previewValues,
    setPreviewValues,
    segmentation,
    setSegmentation,
    deepLinkingEnabled: appProperties?.DisplayDeepLinking === '1' && !!appProperties?.DeepLinkingDefaultLinkDomain,
    isLoading:
      oneSignalNotificationsIsLoading ||
      oneSignalSegmentsIsLoading ||
      secureAppPropertiesIsLoading ||
      appPropertiesIsLoading,
    isError:
      oneSignalNotificationsIsError || oneSignalSegmentsIsError || secureAppPropertiesIsError || appPropertiesIsError,
  };
};
