import { useCallback } from 'react';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { ContentTitle } from 'components';

interface ExternalTitleProps {
  placeholder?: string;
}

export const ExternalTitle = ({
  Id,
  CustomDisplayName,
  DisplayName,
  placeholder,
}: Pick<BuilderProduct, 'Id' | 'CustomDisplayName' | 'DisplayName'> & ExternalTitleProps) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();

  const handleTitleChange = useCallback(
    (title: string) => {
      setProductValue(Id, 'CustomDisplayName', title);
      setProductValueToSave(Id, 'CustomDisplayName', title || DisplayName); // If field is empty, use DisplayName value instead
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <ContentTitle
      label="External Title"
      size="large"
      onChange={handleTitleChange}
      name={CustomDisplayName}
      placeholder={placeholder}
    />
  );
};
